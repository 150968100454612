<template>
<b-overlay :show="loading">
  <form-filter-export @onExport="exportAnalisis" :type_export="['excel']">
      <template #default>
        <b-form-group v-if="!isSales" label="Sales (opsional)" class="mr-2">
          <v-select :options="salesOptions" v-model="dataExport.sales_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
      </template>
    </form-filter-export>
  <b-card-actions action-collapse title="Daftar Analisis / Market Survey">
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-tambah
          variant="primary"
          v-if="allowCreate()"
          @click.prevent="$router.push('/analisis/add')"
        >
          <!-- @click="add()" -->
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('Add') }}
        </b-button>
        <b-modal
          v-if="allowCreate() || allowUpdate()"
          v-model="showModal"
          size="lg"
          id="modal-"
          ok-title="Tutup"
          ok-variant="secondary"
          ok-only
          centered
          title="Form "
        >
          <validation-observer ref="formanalisis">
            <div class="d-flex">
              <feather-icon icon="PackageIcon" size="19" />
              <h6 class="ml-50 mb-2">Data Market Survey</h6>
            </div>
            <b-form>
              <b-row>
                <!-- Tanggal Ajuan -->

                <b-col cols="12">
                  <b-form-group
                    label="Pilih Sales"
                    label-for="v-karyawan_id"
                    v-if="this.userData.level.id != 7"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="karyawan_id"
                      :rules="{ required: true }"
                    >
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="form.karyawan_id"
                        :options="karyawan_id"
                        id="v-karyawan_id"
                        name="karyawan_id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Pilih Sales"
                    label-for="v-karyawan_id"
                    v-if="this.userData.level.id == 7"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="karyawan_id"
                      :rules="{ required: true }"
                    >
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="form.karyawan_id"
                        :options="karyawan_id"
                        id="v-karyawan_id"
                        name="karyawan_id"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Selling" label-for="v-costumer_id">
                    <validation-provider
                      #default="{ errors }"
                      name="costumer_id"
                      :rules="{ required: true }"
                    >
                      <!-- <v-select
                        v-model="form.costumer_id"
                        :options="costumer_id"
                        label="text"
                      /> -->
                      <b-form-input
                        hidden
                        :state="errors.length > 0 ? false : null"
                        v-model="form.costumer_id"
                        id="v-costumer_id"
                        placeholder="Isi Nama Customer / Konsumen"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- nama_costumer -->
                <b-col cols="12">
                  <b-form-group
                    label="Nama Customer / Konsumen"
                    label-for="v-nama_costumer"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="nama_costumer"
                      rules="required|min:2"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.nama_costumer"
                        id="v-nama_costumer"
                        placeholder="Isi Nama Customer / Konsumen"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- alamat -->
                <b-col cols="12">
                  <b-form-group label="alamat" label-for="v-alamat">
                    <validation-provider
                      #default="{ errors }"
                      name="alamat"
                      rules="required|min:2"
                    >
                      <b-form-textarea
                        :state="errors.length > 0 ? false : null"
                        v-model="form.alamat"
                        id="v-alamat"
                        placeholder="Isi alamat"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- kecamatan -->
                <b-col cols="12">
                  <b-form-group label="Kecamatan" label-for="v-kecamatan">
                    <validation-provider
                      #default="{ errors }"
                      name="kecamatan"
                      rules="required|min:2"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.kecamatan"
                        id="v-kecamatan"
                        placeholder="Isi Kecamatan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- brand_selling -->
                <b-col cols="12">
                  <b-form-group label="Brand : Selling" label-for="v-brand_selling">
                    <validation-provider
                      #default="{ errors }"
                      name="brand_selling"
                      rules="required|min:2"
                    >
                      <b-form-textarea
                        :state="errors.length > 0 ? false : null"
                        v-model="form.brand_selling"
                        id="v-brand_selling"
                        placeholder="Rokok : 15000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- keterangan -->
                <b-col cols="12">
                  <b-form-group label="Keterangan" label-for="v-keterangan">
                    <validation-provider
                      #default="{ errors }"
                      name="keterangan"
                      rules="required|min:1"
                    >
                      <b-form-textarea
                        :state="errors.length > 0 ? false : null"
                        v-model="form.keterangan"
                        id="v-keterangan"
                        placeholder="Isi Keterangan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit and reset -->
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    @click.prevent="submit"
                    variant="primary"
                    class="mr-1"
                  >
                    Add
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          :label="$t('Sort')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('Filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template #cell(show_detail)="row">
            <b-form-checkbox
              v-if="row.item.brand && row.item.brand.length > 0"
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <!-- <i class="vs-icon feather icon-check" /> -->
                </span>
              </span>
              <span class="vs-label">{{ row.detailsShowing ? "Hide" : "Show" }}</span>
            </b-form-checkbox>
            <span v-else class="text-danger">
              <feather-icon icon="SlashIcon" />
            </span>
          </template>
          <!-- / -->
          <!-- Detail -->
          <template #row-details="{ item, toggleDetails }">
            <b-card>
              <b-row class="mb-2">
                <b-col cols="12">
                  <table>
                    <tr>
                      <th>No</th>
                      <th>Brand</th>
                      <th>Harga</th>
                    </tr>
                    <tr :key="i" v-for="(br, i) in item.brand">
                      <td>{{ i + 1 }}</td>
                      <td>{{ br.brand ? `${br.brand}` : "-" }}</td>
                      <td>{{ br.harga ? formatRupiah(`${br.harga}`) : "-" }}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>

              <b-button size="sm" variant="outline-secondary" @click="toggleDetails">
                Hide
              </b-button>
            </b-card>
          </template>
          <template #cell(no)="{ index }">
            {{ index + 1 }}
          </template>
          <!-- <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template> -->

          <template #cell(costumer_id)="data">
            {{ getsalesName(data.item) }}
          </template>

          <template #cell(actions)="row">
            <b-button
              title="'Ubah'"
              v-if="allowUpdate()"
              size="sm"
              @click.prevent="$router.push(`/analisis/edit/${row.item.id}`)"
              class="mr-1"
              variant="outline-info"
            >
              <!-- @click="edit(row.item)" -->
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="allowDelete()"
              title="'Hapus'"
              size="sm"
              @click="remove(row.item)"
              class="mr-1"
              variant="outline-danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <!-- <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template> -->
        </b-table>
      </b-col>
      <b-modal :id="detailmodal.id" :title="detailmodal.title" ok-only>
        <pre>{{ detailmodal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalData"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
          @page-click="onPageClick"
        >
        </b-pagination>
      </b-col>
    </b-row>
  </b-card-actions>
</b-overlay>
</template>

<script>
import FormFilterExport from '@core/components/form-filter-export/FormFilterExport.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import mixin from './mixin'
import {
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BCardText,
  BForm,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  mixins: [mixin],
  components: {
    FormFilterExport,
    flatPickr,
    BForm,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    perPage(page) {
      if(page) {
        this.getData()
      }
    },
    filter(val) {
      if(val && val.length > 2) {
        this.getData()
      }
    }
  },
  data() {
    return {
      dataExport: {
        tgl_awal: null,
        tgl_akhir: null,
        sales_id: null
      },
      salesOptions: [],
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        //   costumer_id: "",
        //   tanggal_hktu: new Date(),
        // keterangan: "",
        // costumer_id: "",
        karyawan_id: "",
        costumer_id: new Date(),
        nama_costumer: "",
        alamat: "",
        kecamatan: "",
        brand_selling: "", // input harus menggunakan Brand:Harga
        brand: [],
      },
      costumer_id: [],
      karyawan_id: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 10,
      pageOptions: [5, 10, 15],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loading: false,
      fields: [
        { key: "show_detail", label: "#" },
        {
          key: "no",
          label: "no",
        },
        { key: "tanggal", label: "tanggal", sortable: true },
        { key: "karyawan.nama_lengkap", label: "Sales", sortable: true },
        { key: "tipe.nama", label: "tipe", sortable: true },
        { key: "nama_costumer", label: "Konsumen", sortable: true },
        { key: "kecamatan", label: "kecamatan", sortable: true },
        { key: "alamat", label: "alamat", sortable: true },
        // { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
    };
  },
  computed: {
    totalData() {
      return this.$store.state.analisis.total
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData.id) {
      this.form.karyawan_id = userData.karyawan.id;
    }
    this.getData();
    // this.getcostumer_id();
    this.getsales_id();
  },
  created() {
    if(!this.isSales) {
      this.getSales()
    }
  },
  methods: {
    async exportAnalisis({tgl_awal, tgl_akhir, type_export}) {
      if(!tgl_awal || !tgl_akhir) {
        this.displayError({
          message: 'Harap isi tanggal awal dan akhir!'
        })
        return false
      }

      // validate type_export pdf || excel
      const params = {
        tgl_awal,
        tgl_akhir,
        type_export
      }

      params.karyawan_id = this.isSales ? this.user.id : this.dataExport.sales_id

      try {
        this.loading = true
        const response = await this.printAnalisis(params)
        this.loading = false
        if(type_export == 'pdf') {
          window.open(URL.createObjectURL(response))
        }
        else {
          await window.open(response)
          setTimeout(async () => {
            // delete selected file
            const arrFile = response.split('/')
            const filename = arrFile[ arrFile.length - 1 ]

            await this.clearExportedFile(filename)
          }, 1000)
        }
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    async getSales() {
      const levels = await this.$store.dispatch('level/getData', {
          search: 'sales'
      })
      const salesLevel = levels.find(level => this.getShortName(level.nama_level) == 'sales')
      const params = {}
      if(salesLevel) params.level_id = salesLevel.id

      const sales = await this.$store.dispatch('pengguna/getData', params)
      this.salesOptions = sales.map(item => ({
          value: item.karyawan.id,
          text: item.karyawan.nama_lengkap
      }))
    },
    onPageClick(e, page) {
      e.preventDefault()
      page = page < 1 ? page : page - 1
      this.currentPage = page * this.perPage
      this.getData()
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form = item;
      this.id = item.id;
      if (this.isSales) {
        this.form.karyawan_id = this.user.karyawan.id;
      }
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.id) {
        this.form.karyawan_id = userData.karyawan.id;
      }
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data Analisis Market Survey ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("analisis/save", [item])
            .then(() => {
              this.getData();
              this.displaySuccess({
                message: "Analisis / Market Survey berhasil dihapus!",
              });
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        karyawan_id: "",
        costumer_id: 1,
        nama_costumer: "",
        alamat: "",
        kecamatan: "",
      };

      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.id) {
        this.form.karyawan_id = userData.karyawan.id;
      }
    },
    submit() {
      this.$refs.formanalisis.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          if (this.id) {
            this.form.id = this.id;
          }
          const payload = this.form;
          this.$store
            .dispatch("analisis/save", [payload])
            .then(() => {
              this.label = "Submit";
              this.resetForm();
              this.showModal = false;
              this.getData();
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    async getData() {
      this.loading = true
      const params = {
        start: this.currentPage < 1 ? this.currentPage : (parseInt(this.currentPage) - 1),
        length: this.perPage,
        order: 'desc'
      }
      if(this.filter && this.filter != '') {
        params.search = this.filter
      }
      if(this.isSales && this.user.karyawan) {
        params.karyawan_id = this.user.karyawan.id
      }
      if (this.userData.level.id != 7) {
        //SEMUA kecuali SALES
        this.items = []
        await this.$store.dispatch("analisis/getData", params).then(() => {
          this.items = this.$store.state.analisis.datas;
          this.totalRows = this.items.length;
        });
        this.loading = false
      }
      if (this.userData.level.id == 7) {
        //SALES
        this.items = []
        await this.$store
          .dispatch("analisis/getData", params)
          .then(() => {
            this.items = this.$store.state.analisis.datas;
            this.totalRows = this.items.length;
          });
          this.loading = false
      }
    },
    getcostumer_id() {
      this.$store
        .dispatch("ajuankonsumen/getData", {})
        .then(() => {
          let konsumen = JSON.parse(
            JSON.stringify(this.$store.state.ajuankonsumen.datas)
          );
          konsumen.map((item) => {
            this.costumer_id.push({
              value: item.id,
              text: item.nama_owner + " - " + item.nama_toko,
            });
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getsales_id() {
            let payload = {jabatan_id: 8}
      if (this.isCabang){
        payload.member_id = this.user.member_id
      }
      this.$store
        .dispatch("karyawan/getData", payload)
        .then(() => {
          let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
          karyawan.map((item) => {
            item.value = item.id;
            item.text = item.nama_lengkap;
          });
          this.karyawan_id = karyawan;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getsalesName(data) {
      let find = this.costumer_id.find((item) => item.id == data.sales.id);
      return find ? find.nama_owner : "-";
    },

    async getStatus() {
      this.statusOptions = await this.$store.dispatch("statusrole/getData", {
        menu_id: this.currMenu.id,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
